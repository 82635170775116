import { ConfigService } from "../resources/services/ConfigService";
import * as environment from "../../config/environment.json";
import { FhirService } from "../resources/services/FhirService";
import { RuntimeInfo } from "../resources/classes/RuntimeInfo";
import { UserService } from "../resources/services/UserService";
import { autoinject } from "aurelia-framework";
import { DialogService } from "aurelia-dialog";
import { Router } from "aurelia-router";
import { LocationService } from "resources/services/LocationService";
import { ModalTodoListProcedureEdit } from "resources/elements/modal-todo-list-procedure-edit";
import { fhirEnums } from "../resources/classes/fhir-enums";
import ResourceType = fhirEnums.ResourceType;
import { CarePlanService } from "resources/services/CarePlanService";
import moment = require("moment");
import { QuestionnaireDialogContainer } from "resources/elements/questionnaire/questionnaire-dialog-container";
import { BasicForm } from "resources/elements/BasicForm";
import { QuestionnaireService } from "resources/services/QuestionnaireService";
import { translations } from "resources/classes/translations";
import { I18N } from "aurelia-i18n";
import { PatientService } from "resources/services/PatientService";
import { ModalQRenderer } from 'resources/elements/modal-q-renderer';
import { MedboardQuestionnaireContainer } from "resources/elements/questionnaire/medboard-questionnaire-container";

@autoinject
export class WardOverview {
    router: Router;
    locationService: LocationService;
    fhirService: FhirService;
    carePlanService: CarePlanService;
    i18n: I18N;
    patientService: PatientService;
    wardId = null;
    iframeUrl;
    iframeOrigin;
    iframeContainer;
    iframeListener;
    ward;
    config;
    userService;
    dialogService;
    dialogIsOpen = false;

    constructor(
        userService: UserService,
        dialogService: DialogService,
        router: Router,
        locationService: LocationService,
        fhirService: FhirService,
        carePlanService: CarePlanService,
        i18n: I18N,
        patientService: PatientService
    ) {
        this.router = router;
        this.userService = userService;
        this.dialogService = dialogService;
        this.locationService = locationService;
        this.fhirService = fhirService;
        this.carePlanService = carePlanService;
        this.i18n = i18n;
        this.patientService = patientService;
        this.iframeListener = async (event) => {
            if (!event) return;
            if (event.origin === this.iframeOrigin) {
                const data = event.data?.data;

                switch (event.data?.name) {
                    case "editProcedure": {
                        let procedure
                        let procedureRequest
                        let carePlan
                        let patient
                        let parsedCodeSystem


                        try {
                            const parsedData = JSON.parse(data)
                            procedure = parsedData.resource
                            procedureRequest = parsedData.procedureRequest
                            carePlan = parsedData.carePlan
                            patient = parsedData.patientResource
                            parsedCodeSystem = parsedData.codeSystem
                        } catch (e) {
                            console.error(e)
                            return
                        }

                        const procedureRequestTypeCategory =
                            procedureRequest.category &&
                            procedureRequest.category.find((category) => category.coding[0].system === "http://nursit-institute.com/fhir/StructureDefinition/procedure-request-type");
                        const procedureRequestPrescriptionCategory =
                            procedureRequest.category && procedureRequest.category.find((category) => category.coding[0].system === "http://nursiti.com/CodeSystem/presc-needed");
                        const procedureRequestItem = {
                            resource: procedureRequest,
                            isActive: true,
                            isControlled: procedureRequestTypeCategory ? procedureRequestTypeCategory.coding[0].code === "controlled" : false,
                            isPrescription: Boolean(procedureRequestPrescriptionCategory),
                            qualification:
                                procedureRequest.performerType && procedureRequest.performerType.coding[0].system === "http://nursiti.com/CodeSystem/qualification"
                                    ? procedureRequest.performerType.coding[0].code
                                    : "",
                            duration: CarePlanService.parseCodeSystemDuration(procedureRequest.occurrenceTiming),
                        };

                        this.dialogService
                            .open({
                                viewModel: ModalTodoListProcedureEdit,
                                model: {
                                    procedure: procedure,
                                    procedureRequest: procedureRequestItem,
                                    carePlan: carePlan,
                                    patient: patient,
                                    codeSystem: parsedCodeSystem,
                                },
                            })
                            .whenClosed((result) => {
                                if (!result.wasCancelled) {
                                    this.iframeContainer.contentWindow.postMessage(
                                        {
                                            name: "refresh",
                                        },
                                        this.iframeUrl
                                    );
                                }
                            });
                        break;
                    }
                    case "openQuestionnaireResponse": {
                        const encounter = await this.fhirService.get(`${ResourceType.encounter}/${data.encounterId}`);
                        const patient = await this.patientService.fetch(encounter.id);
                        
                        let responseBody = data?.qrBody ? JSON.parse(data.qrBody) : undefined;
                        if (!responseBody && data?.qrId) {
                            responseBody = patient.questionnaireResponses.find(o=> o.id === data.qrId);
                        }

                        if (!responseBody) {
                            throw new Error(`No Response with the given id "${data.qrId}" found.`);
                        }

                        let dialogOptions = data?.options ? JSON.parse(data.options) : {};

                        await this.openModalAnalyseWindow(encounter, patient, data.qId, responseBody, dialogOptions);
                        break;
                    }
                    case "goToEncounter": {
                        this.router.navigate(`#/encounter/${data.encounterId}/details`);
                        break;
                    }
                }
            }
        };
    }

    protected async openModalAnalyseWindow(encounter, patient, questionnaireId, questionnaireResponse, dialogOptions) {
        if (patient.isOffline) return;
        if (!questionnaireResponse) {
            console.warn(`No Response given!`);
            return;
        }

        const currentTitle = BasicForm.pageTitle;

        let currentQuestionnaire = QuestionnaireService.GetQuestionnaireDirect(questionnaireId);

        // when the questionnaire has not been already loaded, then get it from Fhir:
        if (!currentQuestionnaire) {
            currentQuestionnaire = await this.fhirService.get(`Questionnaire/${questionnaireId}`);

            // when it has been found then add it to the QuestionnaireService
            if (currentQuestionnaire) {
                if (currentQuestionnaire.status != 'active') {
                    // change the status locally to active, so it may be found later
                    console.warn(`Loaded Questionnaire "${currentQuestionnaire.name||currentQuestionnaire.title}" but it has status "${currentQuestionnaire.status}"`);
                    currentQuestionnaire.status = 'active';
                }

                // finally add the resulting Q.
                QuestionnaireService.__questionnaires.push(currentQuestionnaire);
            }
        }

        const currentStatus = translations.translate(questionnaireResponse.status);

        const dialogSettings = {
            grouplist: false,
            response: questionnaireResponse,
            questionnaire: currentQuestionnaire,
            encounter: encounter,
            patient: patient,
            tooold: false,
            haschanges: false,
            dialogService: this.dialogService,
            status: currentStatus,
            saveText: this.i18n.tr("save"),
            abortText: this.i18n.tr("abort"),
            forcedReadonly: false,
            advancedSaveButton: true,
            needsEditButton: true,            
            patientService: this.patientService,
            allowAddNew: false,
            showSelection: false,
            showToolbar: false,
            removeNoToolbarWindow: false,
            data: {
                title: currentQuestionnaire.title || currentQuestionnaire.name,
            },
            showDate: dialogOptions.showDate ?? false,
            hidePrintButton: dialogOptions.hidePrintButton ?? false,
        };

        const qSupportsNewRenderer = currentQuestionnaire?.meta?.profile?.includes("http://hl7.org/fhir/uv/sdc/StructureDefinition/sdc-questionnaire-render")
        const viewModel = RuntimeInfo.Features.qRenderer?.enabled && qSupportsNewRenderer ? ModalQRenderer : MedboardQuestionnaireContainer;

        return this.dialogService
            .open({ viewModel, model: dialogSettings, lock: true })
            .whenClosed(async (result) => {
                BasicForm.pageTitle = currentTitle;

                if (!result.wasCancelled) {
                    this.iframeContainer.contentWindow.postMessage({
                        name: 'questionnaire-dialog-closed',
                        data: JSON.stringify(result.output)
                    }, this.iframeUrl);
                } else {
                    this.iframeContainer.contentWindow.postMessage({
                        name: 'questionnaire-dialog-closed',
                        data: null
                    }, this.iframeUrl);
                }
            })
            .catch((e) => {
                console.warn(e);
            });
    }

    activate(params) {
        if (params["id"]) {
            this.wardId = params["id"];
            this.ward = LocationService.LocationById(this.wardId);
        }

        this.config = ConfigService.GetFormSettings(ConfigService.FormNames.WardOverview);
    }

    attached() {
        const loginData = sessionStorage.getItem(environment.sessionName);

        window.addEventListener("message", this.iframeListener);

        const query = {
            login: loginData,
            server: FhirService.Endpoint,
            ward: this.wardId,
            practitionerId: "",
            origin: window.location.origin,
            lang: RuntimeInfo.Language
        };

        if (this.userService.practitioner) {
            query.practitionerId = this.userService.practitioner.id;
        }

        this.iframeUrl =
            `${this.config.settings && this.config.settings.iframe && this.config.settings.iframe.url}?` +
            Object.keys(query)
                .map((key) => {
                    return `${key}=${encodeURIComponent(query[key])}`;
                })
                .join("&");
        this.iframeOrigin = this.iframeUrl ? this.iframeUrl.match(/^https?\:\/\/([^\/?#]+)/i)[0] : "";

        RuntimeInfo.TogglePatientListLG(true);
    }

    detached() {
        window.removeEventListener("message", this.iframeListener);

        RuntimeInfo.TogglePatientListLG(false);
    }
}
